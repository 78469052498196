<template>
  <div class="project-management-page mx-auto bg-purple-bg">
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ページタイトル「カテゴリを探す」 -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <section class="text-3xl mb-20 font-semibold text-white">
      {{ $t('SEARCH_FOR_CATEGORIES') }}
    </section>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓「カテゴリ名」入力欄、「カテゴリを探す」ボタン、 ↓ -->
    <!-- ↓「追加」ボタンの行                               ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="search-header mb-20 flex items-center justify-between mx-auto
                relative"
    >
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓「カテゴリ名」を入力する空欄と「カテゴリを探す」ボタン ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-input-search
        class="lg:w-7/12 w-full mx-auto"
        v-model="searchText"
        :placeholder="$t('CATEGORY_NAME')"
        :enter-button="$t('CATEGORY_SEARCH_BTN')"
        @search="handleSearch"
      />
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑「カテゴリ名」を入力する空欄と「カテゴリを探す」ボタン ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 「追加」ボタン ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div v-if="userRole === 'admin'">
        <router-link
          to="/manage/user-categories/create"
          class="bg-primary hover:opacity-60 text-white font-medium h-8
                  flex items-center justify-center px-4 rounded-md text-xl"
        >
          {{ $t('ADD_BTN_TEXT') }} <!-- 「追加」-->
        </router-link>
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 「追加」ボタン ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑「カテゴリ名」入力欄、「カテゴリを探す」ボタン、 ↑ -->
    <!-- ↑「追加」ボタンの行                               ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ カテゴリテーブルのレコード 10 レコード分 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div
      v-if="userRole === 'admin'"
      class="text-right"
    >
      <div
        v-if="!loading"
        class="mb-16 bg-purple-bg"
      >
        <CategoryTable :data="categories"></CategoryTable>
      </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ カテゴリテーブルのレコード 10 レコード分 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ ページ表示 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
      <Pagination
        v-if="totalCategories > 0"
        class="text-center mt-8"
        :total="totalCategories"
        :pageSize="limit"
        :current="currentPage"
        @onChange="onChange"
        :showQuickJumper="false"
      ></Pagination>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ ページ表示 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CategoryTable from '@/components/category-management/CategoryTable';
import Pagination    from '@/components/common/Pagination.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'CategoryManagementPage',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    CategoryTable,
    Pagination   ,
  }, // End of components: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      searchText : '',
      data       : [],
      limit      : 10,
      currentPage: 1 ,
      loading    : false,
    }; // End of return {...}
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapGetters({
      userRole: 'wallet/loggedUserRole',
      account : 'wallet/account'       ,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      categories     : (state) => state.categories.categoriesList ,
      totalCategories: (state) => state.categories.totalCategories,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  mounted() {
    this.initCategories();
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    account() {
      this.initCategories();
    },
  }, // End of watch: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    ...mapActions({
      fetchCategories: 'categories/fetchCategories',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    async initCategories() {
      // alert(`START: initCategories: methods: category-management.page.vue`);
      this.loading = true;
      await this.fetchCategories({
        limit: this.limit,
        offset: this.offset,
        search: this.searchText,
        order_by: 'id',
        sort_order: 'asc',
      }); // End of await this.fetchCategories({...}
      this.loading = false;
      // alert(`END  : initCategories: methods: category-management.page.vue`);
    }, // End of  async initCategories() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    handleSearch() {
      this.currentPage = 1;
      this.initCategories();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    onChange(page) {
      console.log('page:', page);
      this.currentPage = page;
      this.initCategories();
    }, // End of onChange(page) {...}
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
.table-wrapper {
  @apply bg-section-bg;
  @apply p-6;
}
::v-deep .ant-input-search {
  .ant-input-group {
    &-addon {
      @apply rounded-r-md;
      @apply bg-primary;
      .ant-btn {
        @apply text-xl bg-primary text-white rounded-r-md border-primary;
      }
    }
    .ant-input {
      background: transparent;
      border: 1px solid white;
      @apply h-9;
      @apply text-white;
      @apply font-medium;
      @apply rounded-none;
      @apply flex items-center;
      background: rgba(16, 16, 16, 0.3);
      @apply text-xl;
      &::placeholder {
        @apply text-white text-xl;
      }
    }
  }
}
</style>

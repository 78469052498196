<template>
  <div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ データが有る場合 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div
      v-if="data && data.length > 0"
      class="project-table pb-4 overflow-auto"
    >
      <a-table
        tableLayout="fixed"
        class="project-list-table"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :rowKey="(record) => `project-management-${record.id}`"
        :customRow="
          (record) => {
            return {
              on: {
                click: (event) => onSelect(record),
              },
            };
          }
        "
        :rowClassName="
          (record) =>
            record.is_deleted ? 'opacity-60' : 'h-max'
        "
      >
        <!-- 列名「カテゴリId」-->
        <span slot="category_id">{{ $t('ID') }}</span>
        <!-- 列名「バナー画像」-->
        <span slot="banner">{{ $t('COLUMN_BANNER') }}</span>
        <!-- 列名「名称」-->
        <span slot="category_name_title">{{ $t('COLUMN_CATEGORY_NAME') }}</span>
        <!-- 列名「種類」 -->
        <span slot="category_type">{{ $t('COLUMN_CATEGORY_TYPE') }}</span>
        <!--列名「階層パス」-->
        <span slot="hierarchy">{{ $t('COLUMN_HIERARCHY') }}</span>
        <!--「作成日時」-->
        <span slot="created_at">{{ $t('COLUMN_CREATED_AT') }}</span>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「カテゴリId」列のカテゴリId ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          slot="category_id"
          slot-scope="text, record"
           class="w-8"
        >
          <span>{{ record.id }}</span>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「カテゴリId」列のカテゴリId ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「バナー画像」列の画像 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          slot="banner"
          slot-scope="text, record"
        >
          <div class="flex flex-col items-center">
            <div class="flex items-center">
              <template v-if="record.is_deleted"> Deleted </template>
            </div>
            <!-- バナー画像 -->
            <div class="mt-2">
              <img
                class="object-cover h-11 w-32"
                :src="record.banner_url"
              />
            </div>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「バナー画像」列の画像 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「カテゴリ名」列のカテゴリ名 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          slot="name"
          slot-scope="text, record"
        >
          <span>{{ locale === 'ja' && record.name_ja ? record.name_ja : record.name_en }}</span>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「カテゴリ名」列のカテゴリ名 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「カテゴリ種類」列の内容 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          slot="category_type"
          slot-scope="text, record"
        >
          <!--<span>{{ record.type }}</span>-->
          <span>
            {{ 
                (
                  locale === 'ja' ?
                    (record.type == 0 ? 'カテゴリ' :
                      (record.type == 1 ? 'プロジェクト' :
                        (record.type == 2 ? 'シリーズ':
                          (record.type == 3 ? 'エディション': '不正値')
                        )
                      )
                    ) :
                    (record.type == 0 ? 'Category' :
                      (record.type == 1 ? 'Project' :
                        (record.type == 2 ? 'Series':
                          (record.type == 3 ? 'Edition' : 'Illegal')
                        )
                      )
                    )
                )
            }}
          </span>
          <!--<span>{{ this.editType(record) }}</span>-->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「カテゴリ種類」列の内容 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「hierarchy」列の内容 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          slot="hierarchy"
          slot-scope="text, record"
        >
          <span>{{ record.hierarchy }}</span>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「hierarchy」列の内容 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓「作成日時」列の内容 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--
        <div
          slot="created_at"
          slot-scope="text"
        >
          <div class="date">
            {{ text | toJST('MM/DD/YYYY HH:mm [(JST)]') }}
          </div>
        </div>
        -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑「作成日時」列の内容 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </a-table>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ データが有る場合 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ データが無かった場合 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <a-empty
      class="mt-32"
      v-else
    ></a-empty>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ データが無かった場合 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// import { locale } from 'core-js';
import { mapGetters, mapState } from 'vuex';
// import EditIcon from '../icons/edit.icon.vue';
// バナー画像、タイプ、hierarchy、カテゴリ名、作成日、編集ボタン（全レコード）とし
// ------+---------+---------+---------+---------+---------+---------+---------E
const columns = [
{
    dataIndex: 'category_id',
    key: 'category_id',
    slots: { title: 'category_id' },
    scopedSlots: { customRender: 'category_id' },
    align: 'right',
    width: 45,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    dataIndex: 'banner',
    key: 'banner',
    scopedSlots: { customRender: 'banner' },
    slots: { title: 'banner' },
    align: 'center',
    width: 200,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    dataIndex: 'name',
    key: 'name',
    slots: { title: 'category_name_title' },
    scopedSlots: { customRender: 'name' },
    align: 'left',
    width: 300,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    dataIndex: 'category_type',
    key: 'category_type',
    scopedSlots: { customRender: 'category_type' },
    slots: { title: 'category_type' },
    align: 'lect',
    width: 150,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    dataIndex: 'hierarchy',
    key: 'hierarchy',
    scopedSlots: { customRender: 'hierarchy' },
    slots: { title: 'hierarchy' },
    align: 'left',
    width: 400,
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // {
  //   dataIndex: 'created_at',
  //   key: 'created_at',
  //   scopedSlots: { customRender: 'created_at' },
  //   align: 'center',
  //   slots: { title: 'created_at' },
  //   width: 200,
  // },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    dataIndex: 'action',
    key: 'action',
    title: '',
    scopedSlots: { customRender: 'action' },
    align: 'right',
  },
]; // End of const columns = [...]
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'CategoryTable',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // components: { EditIcon },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  }, // End of props: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      columns,
    };
  }, // End of Data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapGetters({
     // account: 'wallet/account',
      userRole: 'wallet/loggedUserRole',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    // 整数で表されているカテゴリ種類を加工して見やすくする。
    editType(record) {
      alert(`START: editType: methods`);
      let result;
      if (record.type == 0) {
        result= `${record.type}: (locale ==='ja' ? 'カテゴリ'     :'Category')`;
      } else if (record.type == 1) {
        result= `${record.type}: (locale ==='ja' ? 'プロジェクト' : 'Project')`;
      } else if (record.type == 2) {
        result= `${record.type}: (locale ==='ja' ? 'シリーズ'     : 'Series')` ;
      } else if (record.type == 3) {
        result= `${record.type}: (locale ==='ja' ? 'エディション' : 'Edition')`;
      } else {
        result= '種類不明(Type Unknown)';
      }
      alert(`result=${result}: editType: methods`);
      return result;
    }, // End of editType(record)
    // --+---------+---------+---------+---------+---------+---------+---------E
    // レコードが選択された場合の処理。当該レコードの編集画面に飛ぶ。
    onSelect(record) {
      // is_deleted==1のレコードも選択編集できるようにした。2024/09/25wed
      // if (record.is_deleted) return;
      // this.$router.push(`/manage/categories/${record.id}/edit`);
      const { id } = record;
      // +---------+---------+---------+---------+---------+---------+---------E
      this.$router.push(`/manage/categories/${id}/edit`);
    }, // End of onSelect(record) {...}
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style lang="scss">
.project-table {
  .ant-table {
    min-width: 992px;
    th {
      white-space: nowrap;
    }
    &-body {
      .ant-table-thead {
        tr > th {
          @apply px-2 pt-0 pb-6;
          @apply mx-0;
          background: transparent;
          border: none;
          @apply text-white text-base;

          &:last-child {
            @apply pr-6;
          }
        }
      }
      .ant-table-tbody {
        tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          @apply bg-white opacity-60 text-black;
          .edit-btn {
            @apply border-black text-black;
          }
        }
        tr > td {
          @apply cursor-pointer;
          @apply px-2;
          @apply mx-0;
          @apply bg-transparent text-white border-none;
          @apply py-6;
          @apply text-base;

          &:last-child {
            @apply pr-6;
          }

          .date {
            min-width: 190px;
            @apply flex items-center justify-center;
          }
        }
        tr {
          &:nth-child(even) {
            background: #27154c;
          }
          &:nth-child(odd) {
            background: #321d60;
          }
        }
      }
    }
  }
}
</style>
